import * as Utils from './utils';
import * as Consts from './consts';
import Duration from './duration';

/**
 * Parses date param and returns it as a JS date object
 * @param {*} date UTC string, JS date object, number unix or SuadeTime
 * @param {String} timezone timezone to parse
 * @param {String} format custom format
 * @return {Date} new date object
 */
const parseDate = (date, timezone, format) => {
  if (date === null) return new Date(NaN); // null is invalid
  if (date === undefined) return new Date();
  if (date instanceof Date) return date;
  if (date instanceof Time) return date.$D;
  if (typeof date === 'number') return new Date(date); // Parse timestamp
  if (typeof date === 'string') {
    if (!format) {
      return new Date(NaN);
    }
    if (format.toLowerCase() === Consts.DEFAULT_FORMAT && Consts.ISO_REGEX.test(date)) {
      return dateToUTC(date); // Matches ISO format (either YYYY-MM-DDTHH:mm:ss+00:00 or YYYY-MM-DD)
    }
    if (format.toLowerCase() !== Consts.DEFAULT_FORMAT) {
      return new Date(buildCustomDate(getDateArray(date, format), timezone));
    }
  }
  if (Array.isArray(date)) return new Date(buildCustomDate(date, timezone));
  return new Date(NaN);
};

const dateToUTC = (dateString) => {
  const match = dateString.match(Consts.ISO_REGEX);

  if (match) {
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Months are zero-based in JavaScript
    const day = parseInt(match[3], 10);
    const hour = parseInt(match[4] || '0', 10);
    const minute = parseInt(match[5] || '0', 10);
    const second = parseInt(match[6] || '0', 10);
    const millisecond = parseInt(match[7] || '0', 10);

    const utcDate = new Date(Date.UTC(year, month, day, hour, minute, second, millisecond));
    return utcDate;
  }

  return new Date(dateString);
};

const parseOutputFormat = (format) => {
  const dict = {
    date: 'dateStyle',
    time: 'timeStyle',
    matcher: 'localeMatcher',
    nu: 'numberingSystem',
    numbering: 'numberingSystem',
    system: 'numberingSystem',
    cycle: 'hourCycle',
    hc: 'hourCycle',
    ca: 'calendar',
  };
  if (typeof format === 'string') {
    return format
      .replace(/([a-z]{2,})-(\d?-?[a-z]+\d{0,2})(-)*/gim, (_a, b, c) => b + ':' + c + ' ')
      .trim()
      .split(' ')
      .reduce(
        (acc, a) => ({
          ...acc,
          [dict[a.split(':')[0]] ? dict[a.split(':')[0]] : a.split(':')[0]]: a.split(':')[1],
        }),
        {}
      );
  } else {
    return format;
  }
};

/**
 * Tokenize the array returned by Intl.DateTimeFormat().formatToParts() function, replacing all
 * values with tokens determined by dict
 * @param {Array} parts array of objects {type: String, value: String}
 * @return {Array}
 */
const partsTokenized = (parts) => {
  const dict = {
    day: 'D',
    dayPeriod: 'A',
    era: 'N',
    fractionalSecond: 'S',
    hour: 'H',
    minute: 'm',
    month: 'M',
    relatedYear: 'Y',
    second: 'S',
    timeZoneName: 'z',
    weekday: 'd',
    year: 'Y',
    yearName: 'y',
  };
  return parts.map((item) => {
    const obj = Object.assign({}, item);
    if (obj.type !== 'literal') {
      obj.value = dict[obj.type].repeat(obj.value.length);
    }
    return obj;
  });
};

/**
 * Check if the input contains a valid date
 * @param {Date} date date to validate
 * @return {boolean}
 */
const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

/**
 * Check if the input is a valid Time Zone
 * @param {String} timeZone Time Zone to validate
 * @return {boolean}
 */
const isValidTimezone = (timeZone) => {
  try {
    new Date().toString('en-US', {timeZone: timeZone.toLowerCase()});
  } catch (e) {
    console.warn(e);
    return false;
  }

  return true;
};

/**
 * Check if target unit is valid
 * @param {String} unit unit to validate
 * @return {boolean}
 */
const isValidUnit = (unit) => {
  const validUnits = [Consts.MS, Consts.S, Consts.MIN, Consts.H, Consts.D, Consts.M, Consts.Y];
  return validUnits.includes(unit);
};

/**
 * Check if target array is valid: length [0, 7] and all elements are numbers
 * @param {Array} dateArray date array to validate
 * @return {boolean}
 */
const isValidDateArray = (dateArray) => {
  return (
    Array.isArray(dateArray) &&
    dateArray.length <= Consts.MAX_DATE_ARRAY_LENGTH &&
    dateArray.every((unit) => !isNaN(unit))
  );
};

/**
 * Caclulates offset in milliseconds between target Time Zone and UTC
 * @param {Time} timestamp current Suade Time object
 * @param {String} timeZone target Time Zone to calculate offset
 * @return {number}
 */
const getTimezoneOffset = (timestamp, timeZone) => {
  if (isValidTimezone(timeZone)) {
    const now = new Date(timestamp);
    const tzString = now.toLocaleString('en-US', {timeZone}); // 'en-US' string format is later recognized by 'Date.parse'
    const utcString = now.toLocaleString('en-US', {timeZone: 'UTC'}); // other locales might not
    const diff = Date.parse(utcString) - Date.parse(tzString);
    return -diff;
  } else {
    return null;
  }
};

/**
 * Get the Start/End of the specified unit by calculating the offset between the target Time Zone and UTC
 * @param {Time} sTime Suade Time current instance
 * @param {String} unit unit to calculate the end, e.g. Day, Month, Hour, etc.
 * @param {boolean} isStart boolean to toggle start/end of unit
 * @return {Time}
 */
const getEndOfUnit = (sTime, unit, isStart = true) => {
  // Calculate offset between UTC and specified Time Zone
  const tzOffset = getTimezoneOffset(sTime.valueOf(), sTime.timezone);
  if (tzOffset === null) {
    console.error(
      `[Suade] Time | Timezone is null or not supplied try using ${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      } : current time - ${sTime}`
    );
    return new Time(null); // If not a valid timezone => return STime with invalid date
  }
  // Get UTC time in specified Time Zone
  const tzTimestamp = sTime.valueOf() + tzOffset; // Instance UTC time + timezone offset
  const tzDate = new Time(tzTimestamp);

  let unitGap;
  unit = Utils.prettyUnit(unit);

  // Calculate the 'Start/End of UNIT' for the Timezoned timestamp
  switch (unit) {
    case Consts.Y:
      unitGap = isStart ? Date.UTC(tzDate.year) : Date.UTC(tzDate.year, 11, 31, 23, 59, 59, 999);
      break;
    case Consts.M:
      unitGap = isStart
        ? Date.UTC(tzDate.year, tzDate.month)
        : Date.UTC(tzDate.year, tzDate.month + 1, 0, 23, 59, 59, 999);
      break;
    case Consts.W:
      unitGap = isStart
        ? Date.UTC(tzDate.year, tzDate.month, tzDate.date - tzDate.day + 1)
        : Date.UTC(tzDate.year, tzDate.month, tzDate.date - tzDate.day + 7, 23, 59, 59, 999);
      break;
    case Consts.D:
      unitGap = isStart
        ? Date.UTC(tzDate.year, tzDate.month, tzDate.date)
        : Date.UTC(tzDate.year, tzDate.month, tzDate.date, 23, 59, 59, 999);
      break;
    case Consts.H:
      unitGap = isStart
        ? Date.UTC(tzDate.year, tzDate.month, tzDate.date, tzDate.hour)
        : Date.UTC(tzDate.year, tzDate.month, tzDate.date, tzDate.hour, 59, 59, 999);
      break;
    case Consts.MIN:
      unitGap = isStart
        ? Date.UTC(tzDate.year, tzDate.month, tzDate.date, tzDate.hour, tzDate.minute)
        : Date.UTC(tzDate.year, tzDate.month, tzDate.date, tzDate.hour, tzDate.minute, 59, 999);
      break;
    case Consts.S:
      unitGap = isStart
        ? Date.UTC(tzDate.year, tzDate.month, tzDate.date, tzDate.hour, tzDate.minute, tzDate.second)
        : Date.UTC(tzDate.year, tzDate.month, tzDate.date, tzDate.hour, tzDate.minute, tzDate.second, 999);
      break;
    default:
      console.error(`[Suade] Time | "${unit}" not supported returning null : current time - ${sTime}`);
      return new Time(null);
  }

  // Calculate gap between timezoned timestamp and the 'Start/End of UNIT' of it
  const gap = tzTimestamp - unitGap;
  let computedDate = sTime.valueOf() - gap;
  // Recalculates TZ offset with the new timestamp to avoid TZ change offsets (winter/summer time changes)
  const finalTimeZoneOffset = getTimezoneOffset(computedDate + tzOffset, sTime.timezone);
  if (tzOffset !== finalTimeZoneOffset) {
    computedDate += tzOffset - finalTimeZoneOffset;
  }
  const result = new Time(computedDate, sTime.timezone);

  // If 'naive' mode is on => reset time units
  if (sTime.isDateOnly) {
    result.$D.setUTCHours(0, 0, 0, 0);
    result.init();
  }

  return result;
};

/**
 * Returns a date (timestamp) in the target timezone
 * @param {Array} dateArray datetime units array
 * @param {*} timezone target timezone
 * @return {number}
 */
const buildCustomDate = (dateArray, timezone) => {
  if (Array.isArray(dateArray) && dateArray.length === 0) return Date.now();
  else if (isValidDateArray(dateArray)) {
    const values = [0, 1, 1, 0, 0, 0, 0];
    dateArray.forEach((value, index) => {
      if (value) values[index] = value;
    });
    // Shift the UTC date to match it in the target timezone
    const initialTimestamp = Date.UTC(values[0], --values[1], values[2], values[3], values[4], values[5], values[6]);
    const tzOffset = getTimezoneOffset(initialTimestamp, timezone);
    let tzTimestamp = initialTimestamp - tzOffset;
    const finalTzOffset = getTimezoneOffset(tzTimestamp, timezone);
    tzTimestamp += tzOffset - finalTzOffset;
    return tzTimestamp;
  } else {
    console.warn('[Suade Time]: Provided DateArray is not valid');
    return NaN; // Returns NaN to get an invalid date
  }
};

/**
 * Get date array from custom string
 * @param {String} dateString custom string
 * @param {String} format custom string format
 * @return {Array}
 */
const getDateArray = (dateString, format) => {
  // Added dollar ($) symbol to avoid interfering while replacing the format into groups
  const regex = format
    .replace(/ms/, '(?<ms$>\\d{1,4})') // Got to be first to avoid interfering
    .replace(/DD/, '(?<d$>\\d{2})')
    .replace(/D/, '(?<d$>\\d{1,2})')
    .replace(/MM/, '(?<m$>\\d{2})')
    .replace(/M/, '(?<m$>\\d{1,2})')
    .replace(/YYYY/, '(?<y$>\\d{4})')
    .replace(/YY/, '(?<y$>\\d{2})')
    .replace(/HH/, '(?<h$>\\d{2})')
    .replace(/hh/, '(?<h$>\\d{2})')
    .replace(/h(?!\$)/, '(?<h$>\\d{1,2})')
    .replace(/mm/, '(?<mi$>\\d{2})')
    .replace(/m(?!(i\$))(?!\$)(?!s)/, '(?<mi$>\\d{1,2})')
    .replace(/(ss|SS)/, '(?<s$>\\d{2})')
    .replace(/(s|S)(?!\$)/, '(?<s$>\\d{1,2})')
    .replace(/\u202F/, ' ') // v8 dependency uses this instead of space character between PM/AM since node 22.11
    .replace(/AA/, '(?<a$>([AaPp][Mm]))');

  let result;
  // Can result in some errors e.g. Duplicated group names
  try {
    result = dateString.match(regex);
  } catch (e) {
    console.warn(e);
  }
  if (result) {
    // Some groups were found
    const g = result.groups;
    const tempDateArray = [g.y$, g.m$, g.d$, g.h$, g.mi$, g.s$, g.ms$].map((x) => x || 0);
    const computedDate = new Time(tempDateArray, 'UTC');
    if (g.a$ && g.a$.toLowerCase() === 'pm') {
      computedDate.add(12, 'hour');
    }
    const computedMonth = computedDate.month > 12 ? 12 : computedDate.month + 1;
    const dateArray = [
      computedDate.year,
      computedMonth,
      computedDate.date,
      computedDate.hour,
      computedDate.minute,
      computedDate.second,
      computedDate.millisecond,
    ];
    return dateArray;
  } else {
    // No groups
    console.warn('[Suade Time]: Invalid custom format');
    return null; // buildCustomDate will return a NaN with this return
  }
};

/**
 * Class for creating, manipulating, displaying and querying time
 * Based off dayjs and using the JavaScript Intl library
 */
class Time {
  /**
   * @param {*} date
   * @param {String} timezone
   * @param {String} format
   */

  constructor(date, timezone = Consts.LOCALE, format = Consts.DEFAULT_FORMAT) {
    this.isDateOnly = false; // Default value
    // Set instance timezone checking special cases (NAIVE|LOCALE)
    const compareTimezone = typeof timezone === 'string' ? timezone.toLowerCase() : timezone;

    if (compareTimezone === Consts.NAIVE) {
      this.timezone = Consts.FALLBACK_TIMEZONE;
      this.isDateOnly = true;
    } else if (compareTimezone === Consts.LOCALE) {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else if (!isValidTimezone(compareTimezone)) {
      this.timezone = Consts.FALLBACK_TIMEZONE;
      console.warn(`[Suade Time]: Invalid timezone - fallback to default timezone (${Consts.FALLBACK_TIMEZONE})`);
    } else {
      this.timezone = timezone;
    }

    // Set the STime main field (Date object)
    // Set the STime main field (Date object)
    this.$D = parseDate(date, this.timezone, format);

    // Initialise STime units (UTC)
    this.init();
  }

  /**
   * Set the units of time
   */
  init() {
    // If 'isDateOnly' => reset time units (superfluous)
    if (this.isDateOnly) {
      this.$D.setUTCHours(0, 0, 0, 0);
    }

    this.year = this.$D.getUTCFullYear();
    this.month = this.$D.getUTCMonth();
    this.date = this.$D.getUTCDate();
    this.day = this.$D.getUTCDay();
    this.hour = this.$D.getUTCHours();
    this.minute = this.$D.getUTCMinutes();
    this.second = this.$D.getUTCSeconds();
    this.millisecond = this.$D.getUTCMilliseconds();

    // Throw a warning if STime date is not valid
    if (!isValidDate(this.$D)) {
      console.warn(`[Suade Time]: ${this.$D} - some of the methods may not work as expected.`);
    }
  }

  /**
   * Returns the number of milliseconds since the unix epoch to the SuadeTime
   * @return {Number} time in milliseconds
   */
  valueOf() {
    return this.$D.getTime();
  }

  /**
   * Return SuadeTime in given format
   *
   * "formatString" can either be a custom format which has a corrresponding SuadeTime function
   * or it can be a generic key-value pair of Intl.DateTimeFormat formats
   *
   * @example STime().format('iso')                     // ~> 2021-01-01T00:00:00+00:00
   * @example STime().format('date-short-time-medium')  // ~> 01/01/2021, 00:00:00
   * @param {String} formatString format to return SuadeTime object as string in
   * @param {String/Array} locale return formatted date in this locale
   * @param {String} timezone convert date to this timezone
   * @param {Object} options additional options
   * @return {String}
   */
  format(formatString, locale, timezone, options) {
    if (isValidDate(this.$D)) {
      if (formatString === undefined) {
        return this.toISO();
      }

      const customFormats = {
        timestamp: 'valueOf',
        iso: 'toISO',
        isodate: 'toISODate',
        isotime: 'toISOTime',
        quarter: 'getQuarter',
        yearweek: 'getISOWeekOfYear',
        monthweek: 'getISOWeekOfMonth',
        dateNumeric: 'getDateNumeric',
        timeNumeric: 'getTimeNumeric',
        dateTimeNumeric: 'getDateTimeNumeric',
      };

      const timezoneParsed = this.isDateOnly ? 'UTC' : timezone;

      if (customFormats[formatString]) {
        return this[customFormats[formatString]](locale, timezoneParsed);
      } else {
        const format = {...parseOutputFormat(formatString), timeZone: timezoneParsed, ...options};
        // If locale is undefined, pass an empty array to the Intl.DateTimeFormat function and default to the system locale
        return new Intl.DateTimeFormat(locale || [], format).format(this.$D);
      }
    } else {
      console.warn('[Suade Time]: Format error - invalid date');
    }
  }

  /**
   * Override ES6 class toString method by returning the date as an ISO string
   * @return {String}
   */
  toString() {
    return isValidDate(this.$D) ? this.$D.toISOString() : this.$D;
  }

  /**
   * Updates target STime date and re-initialise it
   * @param {Time} sTime STime object to be set
   * @param {number} timestamp timestamp to set new STime date
   * @return {Time}
   */
  setDate(sTime, timestamp) {
    sTime.$D = new Date(timestamp);
    sTime.init();
    return sTime;
  }

  /**
   * Add a period of time to a SuadeTime and return it as a new SuadeTime
   * @param {Number} number duration of time period to be added
   * @param {String} units unit of duration (millisecond(s)/MS, second(s)/S, minute(s)/M, hour(s)/H, day(s)/D, week(s)/W
   * @param {boolean} absolute added units will be absolute e.g. +1 day = 24h
   * @return {SuadeTime}
   */
  add(number, units, absolute = false) {
    number = Number(number);
    const unit = Utils.prettyUnit(units);

    // Check if provided unit is valid and returns current STime if not
    if (!isValidUnit(unit)) {
      console.warn('[Suade Time]: Invalid unit in ADD class method - returning current STime');
      return this;
    }

    // Relative values
    if (!absolute) {
      // Why year: February can have 28 or 29 days
      const tzOffset = getTimezoneOffset(this, this.timezone);
      const tzTimestamp = this.valueOf() + tzOffset;
      const tzDate = new Time(tzTimestamp);
      // Add the value to the unit=
      tzDate[unit === 'day' ? 'date' : unit] += number; // 'day' !== 'date' in a Date object
      // Check if date is allowed for Month & Year or get nearest one
      let computedDay = tzDate.date;
      if (unit === Consts.Y || unit === Consts.M) {
        const checkDate = new Time(Date.UTC(tzDate.year, tzDate.month, 1), this.timezone);
        const daysInMonth = checkDate.daysInMonth();
        computedDay = Math.min(daysInMonth, tzDate.date);
      }
      this.setDate(
        tzDate,
        Date.UTC(tzDate.year, tzDate.month, computedDay, tzDate.hour, tzDate.minute, tzDate.second, tzDate.millisecond)
      );
      // Get the computed timestamp to the original offset
      let computedDateInOriginalOffset = tzDate.valueOf() - tzOffset;
      // Fix the timezone change offset
      const finalTzOffset = getTimezoneOffset(computedDateInOriginalOffset + tzOffset, this.timezone);
      if (tzOffset !== finalTzOffset) {
        computedDateInOriginalOffset = computedDateInOriginalOffset + tzOffset - finalTzOffset;
      }
      this.setDate(this, computedDateInOriginalOffset);
    } else {
      // Absolute values
      const step =
        {
          [Consts.S]: Consts.MILLISECONDS_A_SECOND,
          [Consts.MIN]: Consts.MILLISECONDS_A_MINUTE,
          [Consts.H]: Consts.MILLISECONDS_A_HOUR,
          [Consts.D]: Consts.MILLISECONDS_A_DAY,
          [Consts.M]: Consts.MILLISECONDS_A_MONTH,
          [Consts.Y]: Consts.MILLISECONDS_A_YEAR,
          // Month and Year logic a bit more difficult to calculate
        }[unit] || 1; // 1 for MS
      this.setDate(this, this.$D.getTime() + number * step);
    }

    // If 'naive' mode is on => reset time units
    if (this.isDateOnly) {
      this.$D.setUTCHours(0, 0, 0, 0);
      this.init();
    }

    return this;
  }

  /**
   * Subtract a period of time to a SuadeTime and return it as a new SuadeTime
   * @param {Number} number duration of time period to be added
   * @param {String} units unit of duration (millisecond(s)/MS, second(s)/S, minute(s)/M, hour(s)/H, day(s)/D, week(s)/W
   * @return {SuadeTime}
   */
  subtract(number, units) {
    return this.add(number * -1, units);
  }

  /**
   * Returns true if the value of this is less than the value of that
   * @param {SuadeTime|String|Number} that STime object to compare with
   * @return {Boolean}
   */
  isBefore(that) {
    that = new Time(that);

    if (this.isEqual(that)) {
      return false;
    } else {
      return this.valueOf() < that.valueOf();
    }
  }

  /**
   * Returns true if the value of this is greater than the value of that
   * @param {SuadeTime|String|Number} that STime object to compare with
   * @return {Boolean}
   */
  isAfter(that) {
    that = new Time(that);

    if (this.isEqual(that)) {
      return false;
    } else {
      return this.valueOf() > that.valueOf();
    }
  }

  /**
   * Returns true if the value of this is between the two values
   * @param {SuadeTime|String|Number} start STime object to compare with
   * @param {SuadeTime|String|Number} end STime object to compare with
   * @return {Boolean}
   */
  isBetween(start, end) {
    start = new Time(start);
    end = new Time(end);

    return this.valueOf() >= start.valueOf() && this.valueOf() <= end.valueOf();
  }

  /**
   * Returns true if the value of this is equal to the value of that
   * @param {SuadeTime|String|Number} that STime object to compare with
   * @param {number} threshold allowed difference between date objects
   * @return {Boolean}
   */
  isEqual(that, threshold = 10) {
    const difference = Math.abs(this.valueOf() - new Time(that).valueOf());
    return difference <= threshold;
  }

  /**
   * Return the number of days in the current month
   * @return {Number}
   */
  daysInMonth() {
    return this.endOf(Consts.M).date;
  }

  /**
   * Return a clone of this
   * @return {SuadeTime}
   */
  clone() {
    return new Time(this.$D, this.timezone);
  }

  /**
   * Switchs current timezone to new one if valid
   * @param {String} newTimezone
   */
  convert(newTimezone) {
    if (isValidTimezone(newTimezone)) {
      this.timezone = newTimezone;
    }
  }

  /**
   * Return the current date as a JS Date object
   * @return {Date}
   */
  toDate() {
    return new Date(this.valueOf());
  }

  /**
   * Returns a STime object containing the start of the specified unit
   * @example STime('2021-05-01T13:20:22').startOf('hour') -> SuadeTime with iso time of '2021-05-01T13:00:00'
   * @param {String} unit e.g. day, month, year
   * @param {String} timezone e.g. 'Europe/London'
   * @return {SuadeTime}
   */
  startOf(unit) {
    return getEndOfUnit(this, unit, true);
  }

  /**
   * Returns a STime object containing the end of the specified unit
   * @example STime('2021-05-01T13:20:22').endOf('hour') -> SuadeTime with iso time of '2021-05-01T13:59:59'
   * @param {String} unit e.g. day, month, year
   * @param {String} timezone e.g. 'Europe/London'
   * @return {SuadeTime}
   */
  endOf(unit) {
    return getEndOfUnit(this, unit, false);
  }

  /**
   * Return SuadeTime as ISO 8601 string with no offset
   *
   * @example STime().toISO() //~> '2021-01-01T00:00:00+00:00'
   * @param {Boolean} zulu Zulu time (zero hour offset)
   * @return {String}
   */
  toISO(zulu = false) {
    if (!isValidDate(this.$D)) {
      return this.$D;
    }
    return zulu === true ? this.$D.toISOString() : this.$D.toISOString().replace(/\.\d{3}Z/, '+00:00');
  }

  /**
   * Return SuadeTime date only in ISO format
   *
   * @example STime().toISO() //~> '2021-01-01'
   * @return {String}
   */
  toISODate() {
    return isValidDate(this.$D) ? this.toISO().split('T')[0] : this.$D;
  }

  /**
   * Return SuadeTime time only in ISO format
   *
   * @example STime().toISO() //~> '23:00:00+01:00'
   * @return {String}
   */
  toISOTime() {
    return isValidDate(this.$D) ? this.toISO().split('T')[1] : this.$D;
  }

  /**
   * Get the quarter of the year the date falls in
   * i.e. Jan-Mar ~> 1, Apr-Jun ~> 2, Jul-Sep ~> 3, Oct-Dec ~> 4
   *
   * @return {Number}
   */
  getQuarter() {
    return Math.floor(this.month / 3) + 1;
  }

  /**
   * Get the ISO week number of the year
   *
   * Find the nearest thursday to a given date and then calculate the difference
   * in weeks between that thursday and the first day of that year (nb: this
   * could be in the previous or next year)
   *
   * "Weeks start with Monday and end on Sunday. Each week's year is the Gregorian
   * year in which the Thursday falls. The first week of the year, hence, always
   * contains 4 January"
   *
   * @example STime('2014-12-29').getISOWeekOfYear()  //~> 1
   * @example STime('2012-01-01').getISOWeekOfYear()  //~> 52
   * @return {Number}
   */
  getISOWeekOfYear() {
    // Make a copy of the date
    const d = new Date(Date.UTC(this.year, this.month, this.date));

    // Get UTC day of week
    const dayNum = d.getUTCDay() || 7;

    // Set to nearest Thursday: current date + 4 - current day number
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);

    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate number of weeks to nearest Thursday : ((difference in milliseconds / a day) + 1) / 7
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

    return weekNo;
  }

  /**
   * Get the ISO week number of the month, from 1-6
   *
   * Calculate the day of the week the month starts with (where getDay returns 0-6, 0 is Sunday and 6 being Saturday)
   * Subtract 2, 1 because getDay starts at 0 (getDate starts at 1) and another because we want to calculate the week based on starting on a monday
   * If the month day starts on a sunday there will be 6 weeks in that month, so we add an offset of 5
   *
   * @example STime('2021-01-01').getISOWeekOfMolocaleFormat: {
        date: dateFormat,
        time: timeFormat,
        dateTime: dateFormat + ' ' + timeFormat,
      },nth()  //~> 1
   * @example STime('2021-01-31').getISOWeekOfMonth()  //~> 5
   * @return {Number} from 0-5
   */
  getISOWeekOfMonth() {
    // Make a copy of the date
    const d = new Date(Date.UTC(this.year, this.month, this.date));

    // Get day of the start of the month
    const monthStartDay = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1)).getUTCDay();

    // Calculate offset date by adding the start day of the month and an offset of -2 or 5 depending on if the first day of the month is a sunday (0)
    const offsetDate = d.getUTCDate() + monthStartDay + (monthStartDay > 0 ? -2 : 5);

    // Return the week index plus 1 to get weeks in the range of 1-6
    return Math.floor(offsetDate / 7) + 1;
  }

  getDateNumeric(locale, timezone) {
    const options = {...Consts.DATE_NUMERIC_OPTIONS, timeZone: timezone};
    return new Intl.DateTimeFormat(locale || [], options).format(this.$D);
  }

  getTimeNumeric(locale, timezone) {
    const options = {...Consts.TIME_NUMERIC_OPTIONS, timeZone: timezone};
    return new Intl.DateTimeFormat(locale || [], options).format(this.$D);
  }

  getDateTimeNumeric(locale, timezone) {
    const options = {...Consts.DATETIME_NUMERIC_OPTIONS, timeZone: timezone};
    return new Intl.DateTimeFormat(locale || [], options).format(this.$D);
  }

  /**
   * Return index in range of 0-6 where 0 is Monday and 6 is Sunday
   * @return {Number}
   */
  getISODayOfWeek() {
    return (this.day + 6) % 7;
  }

  /**
   * Get the relative time from the STime to another date
   * @param {String} locale
   * @param {Date} dateFrom
   * @return {String}
   */
  formatRelative(locale, dateFrom = new Date()) {
    const rtf = new Intl.RelativeTimeFormat(locale, {numeric: 'auto'});

    const units = {
      [Consts.Y]: Consts.MILLISECONDS_A_YEAR,
      [Consts.M]: Consts.MILLISECONDS_A_MONTH,
      [Consts.D]: Consts.MILLISECONDS_A_DAY,
      [Consts.H]: Consts.MILLISECONDS_A_HOUR,
      [Consts.MIN]: Consts.MILLISECONDS_A_MINUTE,
      [Consts.S]: Consts.MILLISECONDS_A_SECOND,
    };

    const elapsed = this.$D - parseDate(dateFrom);

    for (const u in units) {
      if (Math.abs(elapsed) > units[u] || u == 'second') {
        return rtf.format(Math.round(elapsed / units[u]), u);
      }
    }
  }

  /**
   * Get information about the system locale/timezone and default date and time formats
   * @param {String} locale
   * @param {String} timezone
   * @return {Object}
   * @example STime.localeInfo()
   * {
   *  dateFormat: 'DD-MM-YYYY',
   *  timeFormat: 'HH:MM:SS A',
   *  hourCycle: 'h12',
   *  calendar: 'gregory',
   *  day: '2-digit',
   *  locale: 'en-GB',
   *  month: '2-digit'.
   *  numberingSystem: 'latn',
   *  timeZone: 'Europe/London',
   *  year: 'numeric',
   *  parts:[]
   * }
   */
  static localeInfo(locale = [], timezone = undefined) {
    const safeDate = new Date(Date.UTC(2000, 0, 2, 3, 4, 5));
    const dateParts = Intl.DateTimeFormat(locale || [], {dateStyle: 'short', timeZone: timezone}).formatToParts(
      safeDate
    );
    const timeParts = Intl.DateTimeFormat(locale || [], {timeStyle: 'medium', timeZone: timezone}).formatToParts(
      safeDate
    );
    const datePartsTokenized = partsTokenized(dateParts);
    const timePartsTokenized = partsTokenized(timeParts);
    const dateFormat = datePartsTokenized.map((elem) => elem.value).join('');
    const timeFormat = timePartsTokenized.map((elem) => elem.value).join('');
    const getOptions = (opts, timezone) => ({...opts, timezone});
    const inputFormatOptions = ['DATE_NUMERIC_OPTIONS', 'TIME_NUMERIC_OPTIONS', 'DATETIME_NUMERIC_OPTIONS'];
    const inputFormats = [];
    inputFormatOptions.forEach((e) => {
      const inputParts = Intl.DateTimeFormat(locale, getOptions(Consts[e], timezone)).formatToParts(safeDate);
      const inputPartsTokenized = partsTokenized(inputParts);
      const inputFormat = inputPartsTokenized.map((e) => e.value).join('');
      inputFormats.push(inputFormat);
    });

    return {
      ...Intl.DateTimeFormat(locale || [], {timeZone: timezone}).resolvedOptions(),
      hourCycle: Intl.DateTimeFormat(locale || [], {hour: 'numeric', timeZone: timezone}).resolvedOptions().hourCycle,
      parts: datePartsTokenized.concat({type: 'literal', value: ' '}).concat(timePartsTokenized),
      localeFormat: {
        date: dateFormat,
        time: timeFormat,
        dateTime: `${dateFormat} ${timeFormat}`,
      },
      inputFormat: {
        date: inputFormats[0],
        time: inputFormats[1],
        dateTime: inputFormats[2],
      },
    };
  }

  /**
   * Returns the difference between two dates in a SDuration object
   * @param {*} date1  date one to get the difference
   * @param {*} date2  date two to get the difference
   * @return {Duration}
   */
  static diff(date1, date2) {
    const sDate1 = new Time(date1);
    const sDate2 = new Time(date2);
    return new Duration(Math.abs(sDate1.valueOf() - sDate2.valueOf()));
  }
}

export default Time;
